import { Box, Text } from "@chakra-ui/react";

export const TermosUso = () => {
  return (
    <Box>
      <Text mb={2}>
        Ao clicar em <strong>“Aceito todos os termos e condições”</strong>, você
        aceita os termos abaixo:
      </Text>
      <Text mb={2}>
        {/* Eu,{nome} {sobrenome}, portador(a) do CPF {cpf}, autorizo expressamente   */}
        Eu autorizo expressamente que a empresa N1CAR TECNOLOGIA E AGENCIAMENTO
        DE SERVICOS LTDA, doravante denominada N1Car, em razão do processo de
        pré-cadastro de motorista, disponha dos meus dados pessoais e dados
        pessoais sensíveis, a fim de possibilitar o efetivo cadastro.
      </Text>
      <Text mb={2}>
        <strong> CLÁUSULA PRIMEIRA -</strong> Dados: 1) Nome completo; 2) Data
        de nascimento; 3) Número e Imagem da Carteira Nacional de Habilitação
        (CNH); 4) Número e Imagem do Cadastro de Pessoas Físicas – CPF; 5)
        Endereço completo e Imagem do Comprovante de residência; 6) Números de
        telefone, WhatsApp, e endereços eletrônicos;
      </Text>
      <Text mb={2}>
        <strong>CLÁUSULA SEGUNDA -</strong> Finalidade do tratamento de dados:
        O(A) MOTORISTA autoriza, expressamente, que a N1Car utilize os dados
        pessoais e dados pessoais sensíveis listados neste termo para as
        seguintes finalidades: a) Permitir que a EMPRESA identifique e entre em
        contato com o(a) MOTORISTA, em razão do Processo de Cadastramento para
        que possa ser um Motorista apto a figurar na Plataforma da N1Car. b)
        Para aplicação dos critérios de avaliação, seleção e pré-cadastro na
        Plataforma N1Car. c) Para procedimentos de efetivação do cadastro e, se
        atendidas todas as condições, para posterior efetivação na plataforma;
        d) Para cumprimento, pela N1Car, de obrigações impostas por órgãos de
        fiscalização; e) Quando necessário, para atender aos interesses
        legítimos da Empresa ou de terceiros, exceto no caso de prevalecerem
        direitos e liberdades fundamentais do titular que exijam a proteção dos
        dados pessoais;
      </Text>
      <Text mb={2}>
        <strong>CLÁUSULA TERCEIRA -CLÁUSULA TERCEIRA -</strong> Responsabilidade
        pela Segurança dos Dados: A N1Car se responsabiliza por manter medidas
        de segurança técnicas e administrativas suficientes a proteger os dados
        pessoais do(a) MOTORISTA, comunicando ao (a) MOTORISTA, caso aconteça
        qualquer incidente de segurança que possa acarretar risco ou dano
        relevante, conforme o artigo 48 da Lei 13.709/2018.
      </Text>
      <Text mb={2}>
        <strong> CLÁUSULA QUARTA -</strong> Término do Tratamento dos Dados:
        Fica permitido à N1Car manter e utilizar os dados pessoais do(a)
        MOTORISTA durante todo o período em que esse figurar na Plataforma
        N1Car, para as finalidades relacionadas neste termo e, ainda, após o
        término da permanência para cumprimento da obrigação legal ou impostas
        por órgãos de fiscalização, nos termos do artigo 16 da Lei 13.709/2018.
      </Text>
      <Text mb={2}>
        <strong>CLÁUSULA QUINTA - </strong> Direito de Revogação do
        Consentimento: O(A) MOTORISTA poderá revogar seu consentimento, a
        qualquer tempo, por carta eletrônica ou escrita, conforme o parágrafo 5º
        do artigo 8º combinado com o inciso VI do caput do artigo 18 e com o
        artigo 16 da Lei 13.709/2018.
      </Text>
      <Text mb={2}>
        <strong>CLÁUSULA SEXTA -</strong> Tempo de Permanência dos Dados
        Recolhidos: O(A) MOTORISTA fica ciente de que a N1Car deverá permanecer
        com os seus dados pelo período de duração de todo o processo de
        cadastramento, o período de efetivação do cadastro e pelo prazo
        prescricional.
      </Text>
    </Box>
  );
};
