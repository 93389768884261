import { Flex, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";

interface IProps {
  onOpen: () => void;
}

export function Header({ onOpen }: IProps) {
  return (
    <Flex
      width="full"
      height="14"
      paddingInline="5"
      backgroundColor="#181818"
      color="#FFC100"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex flex="1" justifyContent="center" alignItems="center">
        <Link to="/">
          <Image
            src={logo}
            alt="Logo"
            pb={2}
            height="60px"
            objectFit="contain"
            objectPosition="center"
          />
        </Link>
      </Flex>
    </Flex>
  );
}
