import { Box, Flex, Text, useMediaQuery } from "@chakra-ui/react";

import { TERMS } from "../../mocks/terms";
import { Management } from "./modals";

export const Terms = () => {
  const [lessThan1048] = useMediaQuery(["(max-width: 1048px)"]);

  return (
    <Flex
      w={lessThan1048 ? "90%" : "60%"}
      marginY="2rem"
      flexDir="column"
      color="white"
      fontFamily="Poppins"
    >
      <Text fontSize="md" marginBottom="1rem">
        {TERMS.texts.one}
      </Text>
      <Text fontSize="md" marginBottom="0.5rem">
        {TERMS.texts.two}
      </Text>
      <Text>{TERMS.texts.three}</Text>
      <Text>{TERMS.texts.four}</Text>
      <Text>{TERMS.texts.five}</Text>
      <Box marginTop="1rem">
        <Management />
      </Box>
    </Flex>
  );
};
