import {
  Box,
  Button,
  Text,
  Flex,
  Image,
  useMediaQuery,
  Spinner,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Logo from "../../../assets/logo.png";
import { useAuthContext } from "../../../context/auth";
import { AuthRepository } from "../../../services/auth";
import { Dropzone } from "../../dropzone";
import { Pannel } from "./pannel";
import { Footer } from "../../footer";

interface UploadFilesProps {
  onUpload: (files: File[]) => void;
}

export type TDocumentType = "cnh_verse" | "cnh_front" | "crlv";

export type TUploadedImage = { type: TDocumentType; file: File };

export const Dashboard: React.FC<UploadFilesProps> = ({ onUpload }) => {
  const [uploadedImages, setUploadedImages] = useState<TUploadedImage[]>([]);
  const [lessThan524] = useMediaQuery(["(max-width: 1000px)"]);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { signOut, user, token, parent, updateUserCondition } =
    useAuthContext();

  if (!user || !token) {
    navigate("/sign-in");

    return (
      <Box flex={1} justifyContent="center" alignItems="center">
        <Spinner color="#ffcc00" />
      </Box>
    );
  }

  const handleUploadImages = async () => {
    setLoading(true);
    const aux: { [key: string]: string } = {};

    try {
      for await (const image of uploadedImages) {
        const body = new FormData();

        body.append("file", image.file);

        body.append("type", "document");

        const { id } = await AuthRepository.uploadImage(body);

        Object.assign(aux, {
          ...aux,
          [image.type]: id,
        });
      }

      const response = await AuthRepository.updateUserDocuments(
        {
          imagesIds: {
            cnh: {
              front: aux["cnh_front"],
              verse: aux["cnh_verse"],
            },
            crlv: aux["crlv"],
          },
        },
        parent?.id!
      );
      if (response.status === 200) {
        console.log("");
      } else {
        console.log("Falha ao atualizar documentos.");
      }

      updateUserCondition("under-review");
    } catch (e) {
      console.log("Error:", e);
    } finally {
      setLoading(false);
    }
  };

  const handleLogout = () => {
    signOut();
    if (searchParams.get("isPregistered")) return navigate("/operator/sign-up");
    navigate("/");
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      height="100vh"
      justifyContent="space-between"
    >
      <Flex
        flexDirection={["column", "row"]}
        alignItems="center"
        justifyContent={"space-between"}
      >
        <Image src={Logo} p={6} w={lessThan524 ? "27%" : "20%"} marginY={4} />
        <Button mt={[4, 0]} bgColor={"yellow.400"} onClick={handleLogout}>
          SAIR
        </Button>
      </Flex>
      <Box textAlign="center" padding="10px">
        <Flex justifyContent={"space-around"} marginBottom={8}>
          <Text
            p={20}
            fontSize="24px"
            fontFamily="Montserrat"
            textColor="yellow.400"
          >
            Seja bem vindo ao nosso Painel de Controle!
          </Text>
        </Flex>
        <Pannel />
      </Box>
      {user.condition === "pending" ? (
        <>
          <Flex justifyContent="center">
            <div
              className="upload-container"
              style={{ ...styles.container, flexDirection: "column" }}
            >
              <Dropzone
                type="cnh_front"
                uploadText="CNH FRENTE"
                setUploadedImages={setUploadedImages}
              />

              <Dropzone
                type="cnh_verse"
                uploadText="CNH VERSO"
                setUploadedImages={setUploadedImages}
              />

              <Dropzone
                type="crlv"
                uploadText="CRLV"
                setUploadedImages={setUploadedImages}
              />
            </div>
          </Flex>
          <Button
            isLoading={loading}
            loadingText="Carregando"
            onClick={handleUploadImages}
            p={6}
            marginBottom={"200px"}
            bgColor="yellow.400"
          >
            ENVIAR
          </Button>
        </>
      ) : (
        <></>
      )}
      <Box w="full">
        <Footer />
      </Box>
    </Box>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  uploadedImage: {
    maxWidth: "100%",
    maxHeight: "120vh",
  },
};

export default Dashboard;
