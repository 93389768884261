import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Flex,
  Text,
  Button,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { useEffect } from "react";

type Props = {
  isOpen: boolean;
  onClose: VoidFunction;
};

const links = [
  { name: "Metas", link: "metas" },
  { name: "Objetivos", link: "objetivos" },
  { name: "Compromisso", link: "compromisso" },
  { name: "Assistência", link: "assistencia" },
  { name: "Segurança", link: "seguranca" },
  { name: "Contato", link: "contato" },
];

export function Sidebar({ isOpen, onClose }: Props) {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const section = document.getElementById(location.hash.slice(1));
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <Flex>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent bg="#181818">
          <DrawerCloseButton color="white" marginTop="7.5%" />
          <DrawerHeader>
            <Text fontSize="2rem" color="white" paddingX="0.5rem">
              Menu
            </Text>
          </DrawerHeader>
          <Flex>
            <DrawerBody>
              {links.map((link, idx) => (
                <Link to={`/#${link.link}`} key={idx}>
                  <Button bg="#181818" colorScheme="blackAlpha" width="100%">
                    {link.name}
                  </Button>
                </Link>
              ))}
              <Link to="/termos" onClick={onClose}>
                <Button bg="#181818" colorScheme="blackAlpha" width="100%">
                  Termos
                </Button>
              </Link>
              <Link to="/privacidade" onClick={onClose}>
                <Button bg="#181818" colorScheme="blackAlpha" width="100%">
                  Privacidade
                </Button>
              </Link>
              <Link to="/suporte" onClick={onClose}>
                <Button bg="#181818" colorScheme="blackAlpha" width="100%">
                  Suporte
                </Button>
              </Link>
            </DrawerBody>
          </Flex>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
