import { http } from "../index";
import {
  SignUpDto,
  SignInDto,
  TUploadImageResponse,
  TUdateUserDocumentDto,
} from "./dtos";

export const AuthRepository = {
  createDriver: async (formData: FormData) => {
    return await http.post("/auth/registrar", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Tipo-Usuario": "MOTORISTA",
      },
    });
  },

  signUp: async (body: SignUpDto) => {
    const response = await http.post(`/auth/sign-up`, body);
    return response.data;
  },

  verifyEmail: async (tokenEmail: string, codigo: string) => {
    const response = await http.post(
      `/auth/email-verify/${codigo}`,
      {},
      {
        headers: {
          tokenEmail,
        },
      }
    );

    return response.data;
  },

  resendEmail: async (email: string) => {
    const response = await http.post(
      "/auth/resend-email",
      { email },
      {
        headers: {
          "Tipo-Usuario": "MOTORISTA",
        },
      }
    );
    return response.data;
  },

  signIn: async (body: SignInDto) => {
    const response = await http.post(`/auth/sign-in`, body);
    return response.data;
  },

  uploadImage: async (body: FormData) => {
    const response = await http.post<TUploadImageResponse>("/uploads", body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  },

  updateUserDocuments: async (body: TUdateUserDocumentDto, id: string) => {
    const response = await http.patch(`/drivers/documents/${id}`, body);
    return response.data;
  },
};
