import { useState } from "react";
import Chat from "../../assets/images/chat.png";
import AppStore1 from "../../assets/images/AppStore1.png";
import GooglePlay1 from "../../assets/images/GooglePlay1.png";
import { Box, Image, Text } from "@chakra-ui/react";
import "./contacts.css";

function Contacts() {
  const [subject, setSubject] = useState("");
  const [mensagem, setMensagem] = useState("");

  function sendEmail(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (subject === "" || mensagem === "") {
      alert("Preencha todos os campos");
      return;
    }

    const anchor = document.createElement("a");
    anchor.href = `mailto:contato@n1car.com.br?subject=${subject}&body=${mensagem}`;
    anchor.click();

    setSubject("");
    setMensagem("");
  }

  return (
    <div id="contato">
      <Text
        backgroundColor={"black"}
        color="#DCBC2E"
        fontWeight={"300"}
        fontFamily={"Montserrat"}
        paddingTop={"14"}
        textAlign={"center"}
        fontSize={"40"}
      >
        CONTATO
      </Text>

      <div className="container">
        <Box display="flex" flexDir="column">
          <p className="message">
            Queremos te ouvir!
            <br />
            envie sua mensagem para:
            <br />
            <br />
          </p>
          <h3 className="emailN1">contato@n1car.com.br</h3>
          <Image
            boxSize="250px"
            alt=""
            src={Chat}
            className="chat-image"
          ></Image>
        </Box>

        <form className="formulario" onSubmit={sendEmail} noValidate>
          <h2 className="subTitle">Fale conosco!</h2>
          <input
            className="subject"
            type="text"
            placeholder="Digite o assunto:"
            required
            onChange={(e) => setSubject(e.target.value)}
            value={subject}
          />
          <br />

          <textarea
            className="assunto"
            placeholder="Mensagem:"
            required
            onChange={(e) => setMensagem(e.target.value)}
            value={mensagem}
          ></textarea>
          <br />
          <button className="botao" type="submit">
            Enviar
          </button>
        </form>
      </div>
      <Box
        alignItems={"center"}
        flexDirection={["column", "row"]}
        background={"black"}
        display={"flex"}
        justifyContent={"space-around"}
        padding={25}
      >
        <Text
          backgroundColor={"black"}
          color="#DCBC2E"
          textAlign={"center"}
          fontSize={"25"}
          fontFamily={"Montserrat"}
        >
          EM BREVE NAS PRINCIPAIS PLATAFORMAS
        </Text>
        <a href="/" className="appstore">
          <img alt="" src={AppStore1} />
        </a>
        <a href="/" className="googleplay">
          <img alt="" src={GooglePlay1} />
        </a>
      </Box>
    </div>
  );
}

export default Contacts;
