import { Flex } from "@chakra-ui/react";
import { FaLock } from "react-icons/fa";

export function Footer() {
  return (
    <Flex
      width="full"
      height="14"
      bg="#181818"
      align="center"
      justifyContent="center"
      padding={4}
      color="#FFC100"
    >
      <FaLock style={{ marginRight: "8px" }} />
      Todos os direitos reservados
    </Flex>
  );
}
