import axios from "axios";
import { BASE_URL, GLOBAL_PREFIX } from "../configs/constants/http";

const http = axios.create({
  baseURL: `${BASE_URL}${GLOBAL_PREFIX}`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    target: "driver",
  },
});

export { http };
