import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  HStack,
  InputGroup,
  InputRightElement,
  IconButton,
  Image,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import { ChangeEvent, useState } from "react";
import { PatternFormat } from "react-number-format";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { AuthRepository } from "../../services/auth";
import { useNavigate } from "react-router-dom";
import { cpfValidator } from "../../utils/validators/cpf";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { ViewOffIcon, ViewIcon, CloseIcon } from "@chakra-ui/icons";
import { FiUpload } from "react-icons/fi";
import { TermosUso } from "../../Components/main-page/dashboard/termos";

const formSchema = z.object({
  nome: z.string({ required_error: "Campo obrigatório" }),
  sobrenome: z.string({ required_error: "Campo obrigatório" }),
  genero: z.string({ required_error: "Campo obrigatório" }),
  email: z.string().email({ message: "Email inválido" }),
  cpf: z
    .string({ required_error: "Campo obrigatório" })
    .refine(cpfValidator, "CPF inválido"),
  senha: z
    .string({ required_error: "Campo obrigatório" })
    .min(6, { message: "A senha deve ter pelo menos 6 caracteres" }),
  telefone: z.string({ required_error: "Campo obrigatório" }),
  foto: z
    .array(z.object({ file: z.instanceof(File), url: z.string() }))
    .min(1, { message: "Foto obrigatória" }),
});

type TCreateDriverForm = z.infer<typeof formSchema>;

export const SignUp = () => {
  const [accepted, setAccepted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [foto, setFoto] = useState<{ file: File; url: string }[]>([]);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const [modalCheckboxAccepted, setModalCheckboxAccepted] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm<TCreateDriverForm>({
    resolver: zodResolver(formSchema),
    defaultValues: {},
  });

  const removeCpfFormatting = (cpf: string) => {
    return cpf.replace(/\D/g, "");
  };

  const handleModalCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setModalCheckboxAccepted(e.target.checked);
  };

  const handleOpenTermsModal = () => {
    setShowTermsModal(true);
  };

  const handleCloseTermsModal = () => {
    setAccepted(modalCheckboxAccepted);
    setShowTermsModal(false);
  };

  const handleSaveTerms = () => {
    setTermsAccepted(true);
    setShowTermsModal(false);
  };

  const handleCloseSuccessModal = () => {
    setShowSuccessModal(false);
    navigate("/");
  };

  const formatPhoneNumber = (phone: string) => {
    const ddi = phone.slice(0, 2).padStart(3, "0");
    const ddd = phone.slice(2, 4).padStart(3, "0");
    const number = phone.slice(4);
    return `${ddi}${ddd}${number}`;
  };

  const onSubmit: SubmitHandler<TCreateDriverForm> = async (data) => {
    if (!termsAccepted && !modalCheckboxAccepted) {
      setShowTermsModal(true);
      return;
    }

    if (!data.genero) {
      toast({
        title: "Erro",
        description: "Por favor, selecione um gênero.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });
      return;
    }

    try {
      const formData = new FormData();
      formData.append("nome", data.nome);
      formData.append("sobrenome", data.sobrenome);
      formData.append("genero", data.genero);
      formData.append("email", data.email);

      const cpfSemFormatacao = removeCpfFormatting(data.cpf);
      formData.append("cpf", cpfSemFormatacao);

      const telefoneFormatado = formatPhoneNumber(data.telefone);
      formData.append("telefone", telefoneFormatado);

      formData.append("senha", data.senha);

      if (foto.length > 0) {
        formData.append("arquivo", foto[0].file);
      }

      const response = await AuthRepository.createDriver(formData);
      const token = response?.data?.tokenEmail;

      if (!token) {
        throw new Error("Token não encontrado na resposta da API");
      }

      localStorage.setItem("token", token);
      navigate("/verifyEmail", {
        state: { token: token, email: data.email },
      });
    } catch (error: any) {
      const errorMessage =
        error.response?.data?.erros?.[0] ||
        "Ocorreu um erro ao avançar o cadastro, por favor tente novamente mais tarde!";

      toast({
        title: "Erro",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom",
      });

      console.error(
        "Erro na resposta da API:",
        error.response ? error.response : error
      );
    }
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const imageObject = { file, url: URL.createObjectURL(file) };
      setFoto([imageObject]);
      setValue("foto", [imageObject], { shouldValidate: true });
    } else {
      setFoto([]);
      setValue("foto", [], { shouldValidate: true });
    }
  };

  const termsLinkStyles = {
    textDecoration: termsAccepted ? "none" : "underline",
    color: termsAccepted ? "white.500" : "blue.500",
    cursor: "pointer",
  };

  const removeImage = (index: number) => {
    const updatedImages = [...foto];
    updatedImages.splice(index, 1);
    setFoto(updatedImages);
  };

  return (
    <>
      <Flex
        p={{ sm: 6, md: 10 }}
        flexDirection="column"
        maxW={1024}
        mx="auto"
        justify="center"
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box
            onSubmit={() => {}}
            display="flex"
            flexDirection="column"
            gap={6}
            p={12}
            color="#fff"
            borderWidth={1}
            borderColor="#ffcc00"
          >
            <Box>
              <Text fontWeight="bold" fontSize="xl">
                Dados Pessoais
              </Text>
              <SimpleGrid
                borderWidth={1}
                p={4}
                borderColor="#ffcc00"
                columns={{ sm: 1, md: 2 }}
                gap={6}
              >
                <Controller
                  control={control}
                  name="nome"
                  render={({ field }) => (
                    <FormControl isInvalid={errors.nome != null}>
                      <FormLabel>Nome</FormLabel>
                      <Input
                        {...field}
                        focusBorderColor="#fff"
                        placeholder="Nome"
                      />
                      <FormErrorMessage>
                        {errors.nome?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name="sobrenome"
                  render={({ field }) => (
                    <FormControl isInvalid={errors.sobrenome != null}>
                      <FormLabel>Sobrenome</FormLabel>
                      <Input
                        {...field}
                        focusBorderColor="#fff"
                        placeholder="Sobrenome"
                      />
                      <FormErrorMessage>
                        {errors.sobrenome?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name="genero"
                  render={({ field }) => (
                    <FormControl isInvalid={errors.genero != null}>
                      <FormLabel>Gênero</FormLabel>
                      <Select {...field}>
                        <option style={{ color: "gray" }} value="">
                          Selecione
                        </option>
                        <option style={{ color: "black" }} value="MASCULINO">
                          Masculino
                        </option>
                        <option style={{ color: "black" }} value="FEMININO">
                          Feminino
                        </option>
                        <option style={{ color: "black" }} value="OUTRO">
                          Outros
                        </option>
                      </Select>
                      <FormErrorMessage>
                        {errors.genero?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name="cpf"
                  render={({ field }) => (
                    <FormControl isInvalid={errors.cpf != null}>
                      <FormLabel>CPF</FormLabel>
                      <PatternFormat
                        {...field}
                        customInput={Input}
                        format="###.###.###-##"
                        mask="_"
                        focusBorderColor="#fff"
                        placeholder="CPF"
                      />
                      <FormErrorMessage>{errors.cpf?.message}</FormErrorMessage>
                    </FormControl>
                  )}
                />
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <FormControl isInvalid={errors.email != null}>
                      <FormLabel>E-mail</FormLabel>
                      <Input
                        {...field}
                        focusBorderColor="#fff"
                        placeholder="Email"
                        type="email"
                      />
                      <FormErrorMessage>
                        {errors.email?.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />

                <Controller
                  control={control}
                  name="telefone"
                  render={({ field }) => (
                    <FormControl isInvalid={!!errors.telefone}>
                      <Controller
                        control={control}
                        name="telefone"
                        render={({ field }) => (
                          <FormControl isInvalid={errors.telefone != null}>
                            <FormLabel>Telefone</FormLabel>
                            <HStack alignItems="center" mb={4}>
                              <Box>
                                <PhoneInput
                                  {...field}
                                  country={"br"}
                                  inputStyle={{
                                    color: "black",
                                  }}
                                  buttonStyle={{
                                    backgroundColor: "white",
                                    color: "black",
                                  }}
                                  onChange={(value) => field.onChange(value)}
                                  onBlur={field.onBlur}
                                />
                                <FormErrorMessage>
                                  {errors.telefone?.message}
                                </FormErrorMessage>
                              </Box>
                            </HStack>
                          </FormControl>
                        )}
                      />
                    </FormControl>
                  )}
                />
              </SimpleGrid>
            </Box>

            <Box>
              <Text fontWeight="bold" fontSize="xl">
                Foto
              </Text>
              <SimpleGrid
                borderWidth={1}
                p={4}
                borderColor="#ffcc00"
                row={{ sm: 1, md: 2 }}
                gap={6}
              >
                <Controller
                  control={control}
                  name="foto"
                  render={({ field }) => (
                    <FormControl isInvalid={!!errors.foto}>
                      <FormLabel
                        fontWeight="bold"
                        fontSize="lg"
                        color="gray.700"
                      >
                        Escolha uma foto
                      </FormLabel>

                      <Box position="relative" display="inline-block" mb={4}>
                        <Input
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                          display="none"
                          id="fileInput"
                        />

                        <Button
                          color="black"
                          bg="#ECC94B"
                          as="label"
                          htmlFor="fileInput"
                          leftIcon={<FiUpload />}
                          colorScheme="yellow"
                          variant="outline"
                          aria-label="Selecionar imagem"
                          cursor="pointer"
                        >
                          Selecionar Imagem
                        </Button>
                      </Box>
                      <FormErrorMessage>
                        {typeof errors.foto?.message === "string"
                          ? errors.foto.message
                          : null}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />{" "}
                {foto.length > 0 && (
                  <Box>
                    <Text fontSize="md" fontWeight="semibold">
                      Imagens selecionadas:
                    </Text>
                    <Flex mt={2} gap={4}>
                      {foto.map((img, index) => (
                        <Box
                          position="relative"
                          key={index}
                          w="100px"
                          h="100px"
                        >
                          <Image
                            src={img.url}
                            alt="CNH"
                            boxSize="100%"
                            borderRadius="md"
                          />
                          <IconButton
                            aria-label="Remover imagem"
                            icon={<CloseIcon />}
                            size="xs"
                            position="absolute"
                            top="0"
                            right="0"
                            onClick={() => {
                              removeImage(0);
                              setValue("foto", [], { shouldValidate: true });
                            }}
                          />
                        </Box>
                      ))}
                    </Flex>
                  </Box>
                )}
              </SimpleGrid>{" "}
            </Box>
            <Controller
              control={control}
              name="senha"
              render={({ field }) => (
                <FormControl isInvalid={errors.senha != null}>
                  <FormLabel>Senha</FormLabel>
                  <InputGroup   w={["100%", "100%", "40%"]}>
                    <Input
                      {...field}
                      type={showPassword ? "text" : "password"}
                      placeholder="Senha"
                      focusBorderColor="#fff"
                    />
                    <InputRightElement width="3rem">
                      <Button
                        h="1.75rem"
                        size="sm"
                        onClick={toggleShowPassword}
                        bg="white"
                      >
                        {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{errors.senha?.message}</FormErrorMessage>
                </FormControl>
              )}
            />
            <Button
              _hover={{ bgColor: "#ffcc80" }}
              bgColor="#ffcc00"
              p={4}
              alignItems="center"
              justifyContent="center"
              type="submit"
              title="Continuar"
            >
              Cadastrar
            </Button>
            <Checkbox
              mt={1}
              colorScheme="yellow"
              borderColor="gray.400"
              _hover={{ borderColor: "gray.500" }}
              isChecked={modalCheckboxAccepted}
              onChange={handleModalCheckboxChange}
            >
              Aceito os{" "}
              <Text
                as="span"
                onClick={handleOpenTermsModal}
                style={termsLinkStyles}
              >
                Termos de Uso
              </Text>
            </Checkbox>

            <Modal isOpen={showTermsModal} onClose={handleCloseTermsModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Termos de Uso</ModalHeader>
                <ModalBody>
                  <TermosUso />
                  <Checkbox
                    colorScheme="yellow"
                    borderColor="#ECC94B"
                    _hover={{ borderColor: "gray.500" }}
                    mt={4}
                    isChecked={modalCheckboxAccepted}
                    onChange={handleModalCheckboxChange}
                  >
                    Eu li e aceito os Termos de Uso
                  </Checkbox>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="yellow" onClick={handleSaveTerms}>
                    Salvar
                  </Button>
                  <Button ml={3} onClick={handleCloseTermsModal}>
                    Fechar
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
            <Modal isOpen={showSuccessModal} onClose={handleCloseSuccessModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Registro bem-sucedido</ModalHeader>
                <ModalBody>
                  <Text>Seu registro foi realizado com sucesso!</Text>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="green" onClick={handleCloseSuccessModal}>
                    OK
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Box>
        </form>
      </Flex>
    </>
  );
};
