import {
  Box,
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useMediaQuery,
  OrderedList,
  ListItem,
} from "@chakra-ui/react";

import { TERMS_MODALS } from "../../../mocks/terms";

export function Management() {
  const [lessThan756] = useMediaQuery(["(max-width: 756px)"]);
  return (
    <Flex flexDir="column" alignItems="flex-start">
      <Accordion allowToggle w="100%">
        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: "#FDD835", color: "#000" }}>
              <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                {TERMS_MODALS.service.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontSize={lessThan756 ? "0.9rem" : "1rem"}>
            <OrderedList marginTop="0.5rem" fontSize="0.8rem">
              {Object.keys(TERMS_MODALS.service)
                .map((listItem, index) => (
                  <ListItem key={index}>
                    {TERMS_MODALS.service[listItem]}
                  </ListItem>
                ))
                .slice(1)}
            </OrderedList>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: "#FDD835", color: "#000" }}>
              <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                {TERMS_MODALS.service_uses.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontSize={lessThan756 ? "0.9rem" : "1rem"}>
            <OrderedList marginTop="0.5rem" fontSize="0.8rem">
              {Object.keys(TERMS_MODALS.service_uses)
                .map((listItem: string, index: number) => (
                  <ListItem key={index}>
                    {TERMS_MODALS.service_uses[listItem]}
                  </ListItem>
                ))
                .slice(1)}
            </OrderedList>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: "#FDD835", color: "#000" }}>
              <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                {TERMS_MODALS.payment.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontSize={lessThan756 ? "0.9rem" : "1rem"}>
            <OrderedList marginTop="0.5rem" fontSize="0.8rem">
              {Object.keys(TERMS_MODALS.payment)
                .map((listItem: string, index: number) => (
                  <ListItem key={index}>
                    {TERMS_MODALS.payment[listItem]}
                  </ListItem>
                ))
                .slice(1)}
            </OrderedList>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: "#FDD835", color: "#000" }}>
              <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                {TERMS_MODALS.guarantee.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontSize={lessThan756 ? "0.9rem" : "1rem"}>
            <OrderedList marginTop="0.5rem" fontSize="0.8rem">
              {Object.keys(TERMS_MODALS.guarantee)
                .map((listItem: string, index: number) => (
                  <ListItem key={index}>
                    {TERMS_MODALS.guarantee[listItem]}
                  </ListItem>
                ))
                .slice(1)}
            </OrderedList>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: "#FDD835", color: "#000" }}>
              <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                {TERMS_MODALS.controversies.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontSize={lessThan756 ? "0.9rem" : "1rem"}>
            <OrderedList marginTop="0.5rem" fontSize="0.8rem">
              {Object.keys(TERMS_MODALS.controversies)
                .map((listItem: string, index: number) => (
                  <ListItem key={index}>
                    {TERMS_MODALS.controversies[listItem]}
                  </ListItem>
                ))
                .slice(1)}
            </OrderedList>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: "#FDD835", color: "#000" }}>
              <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                {TERMS_MODALS.jurisdiction.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontSize={lessThan756 ? "0.9rem" : "1rem"}>
            <OrderedList marginTop="0.5rem" fontSize="0.8rem">
              {Object.keys(TERMS_MODALS.jurisdiction)
                .map((listItem: string, index: number) => (
                  <ListItem key={index}>
                    {TERMS_MODALS.jurisdiction[listItem]}
                  </ListItem>
                ))
                .slice(1)}
            </OrderedList>
          </AccordionPanel>
        </AccordionItem>

        <AccordionItem>
          <h2>
            <AccordionButton _expanded={{ bg: "#FDD835", color: "#000" }}>
              <Box as="span" flex="1" textAlign="left" fontWeight="bold">
                {TERMS_MODALS.others.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4} fontSize={lessThan756 ? "0.9rem" : "1rem"}>
            <OrderedList marginTop="0.5rem" fontSize="0.8rem">
              {Object.keys(TERMS_MODALS.others)
                .map((listItem: string, index: number) => (
                  <ListItem key={index}>
                    {TERMS_MODALS.others[listItem]}
                  </ListItem>
                ))
                .slice(1)}
            </OrderedList>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  );
}
