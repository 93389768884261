import {
  Box,
  Button,
  Heading,
  Text,
  Img,
  Input,
  Textarea,
  Select,
  useMediaQuery,
  Grid,
  useToast,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
} from "@chakra-ui/react";
import support from "../../assets/images/support.png";
// import ReCAPTCHA from "react-google-recaptcha";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { http } from "../../services";

export function Support() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get("name");
  const [mensagem, setMensagem] = useState("");
  const [assunto, setAssunto] = useState("");
  const [emailOuCpf, setEmailOuCpf] = useState("");
  const [tipoUsuario, setTipoUsuario] = useState("");
  const [selecionado, setSelecionado] = useState("");
  const toast = useToast();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  // const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [screen1000] = useMediaQuery("(max-width: 1000px)");
  const [screen700] = useMediaQuery("(max-width: 700px)");
  const [screen400] = useMediaQuery("(max-width: 400px)");

  useEffect(() => {
    setEmailOuCpf("");
  }, [selecionado]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate("/");
  };

  const sendEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (
      mensagem === "" ||
      emailOuCpf === "" ||
      tipoUsuario === "" ||
      selecionado === ""
    ) {
      toast({
        title: "Preencha todos os campos.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const cpf = selecionado === "cpf" ? emailOuCpf.replace(/\D/g, "") : null;
    const email = selecionado === "email" ? emailOuCpf : null;
    const topico = assunto.toUpperCase().replace(/\s+/g, "_");

    const requestBody = {
      cpf,
      email,
      topico,
      mensagem,
    };

    try {
      const response = await http.post(
        "/suporte/solicitacao-email",
        requestBody
      );
      if (response.status === 200) {
        setIsModalOpen(true);
        setMensagem("");
        setAssunto("");
        setEmailOuCpf("");
        setTipoUsuario("");
        setSelecionado("");
      }
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.erros) {
        error.response.data.erros.forEach((erro: string) => {
          toast({
            title: erro,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
      } else {
        toast({
          title: "Erro ao enviar mensagem, tente novamente.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      // console.error(error);
    }
  };

  const topics = [
    { label: "Conta", value: 0 },
    { label: "Problemas com uma viagem", value: 1 },
    { label: "Ganhos e repasse", value: 2 },
    { label: "Problemas com a conta e o app", value: 3 },
    { label: "Segurança", value: 4 },
    { label: "Acessibilidade", value: 5 },
    { label: "Dados e privacidade", value: 6 },
    { label: "Assinatura e fidelidade", value: 7 },
  ];

  const validarEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const formatarCPF = (cpf: string) => {
    return cpf
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
  };

  return (
    <Grid backgroundColor={"black"} paddingBottom={10}>
      <Heading
        backgroundColor={"black"}
        color="#DCBC2E"
        fontWeight={"300"}
        fontFamily={"Montserrat"}
        paddingTop={"8"}
        textAlign={"center"}
        fontSize={"40"}
      >
        SUPORTE
      </Heading>
      <div>
        <Box
          w={screen700 ? "90%" : "100vw"}
          alignItems={"center"}
          display={"flex"}
          flexDirection={screen1000 ? "column" : "row"}
          height={screen1000 ? "auto" : "570px"}
          justifyContent={screen1000 ? "center" : "center"}
        >
          <Text
            color={"white"}
            justifyItems={"center"}
            fontSize={24}
            textAlign={"center"}
          >
            <Box
              paddingLeft={screen1000 ? 20 : 20}
              marginLeft={screen400 ? 0 : 6}
              paddingEnd={screen1000 ? 10 : 0}
            >
              <Img src={support} />
            </Box>
            Bem-vindo, <b style={{ color: "#DCBC2E" }}>{name} !</b> <br />A{" "}
            <b>N1 Car</b> tem um suporte <br /> para melhor atender nossos
            parceiros!
            <br />
            <br />
          </Text>
          <Box>
            <Text
              backgroundColor={"black"}
              color={"#DCBC2E"}
              fontSize={"26"}
              marginLeft={screen1000 ? 20 : 400}
            >
              Fale com nosso suporte!
            </Text>
            <Box
              marginLeft={screen1000 ? 20 : 400}
              marginEnd={screen1000 ? 10 : 50}
            >
              <form onSubmit={sendEmail} noValidate>
                <Text
                  mt={5}
                  fontSize={"20"}
                  borderRadius={4}
                  height={10}
                  color={"white"}
                  w={screen1000 ? "100%" : "auto"}
                >
                  Selecione CPF ou E-mail:
                </Text>
                <Select
                  w={screen1000 ? "100%" : "auto"}
                  color={"black"}
                  bg={"white"}
                  value={selecionado}
                  onChange={(e) => setSelecionado(e.target.value)}
                  marginTop={2}
                >
                  <option value="">Selecionar</option>
                  <option value="cpf">CPF</option>
                  <option value="email">E-mail</option>
                </Select>

                {selecionado === "cpf" && (
                  <Input
                    w={screen1000 ? "100%" : "100%"}
                    color={"black"}
                    bg={"white"}
                    value={emailOuCpf}
                    onChange={(e) => setEmailOuCpf(formatarCPF(e.target.value))}
                    placeholder="Digite seu CPF"
                    marginTop={2}
                    maxLength={14}
                  />
                )}

                {selecionado === "email" && (
                  <Input
                    w={screen1000 ? "100%" : "100%"}
                    color={"black"}
                    bg={"white"}
                    value={emailOuCpf}
                    onChange={(e) =>
                      setEmailOuCpf(e.target.value.toLowerCase())
                    }
                    placeholder="Digite seu e-mail"
                    marginTop={2}
                    isInvalid={emailOuCpf !== "" && !validarEmail(emailOuCpf)}
                    errorBorderColor="crimson"
                  />
                )}

                <Text
                  fontSize={"20"}
                  borderRadius={4}
                  height={10}
                  color={"white"}
                  marginTop={4}
                >
                  Selecione seu tipo:
                </Text>
                <Select
                  w={screen1000 ? "100%" : "auto"}
                  color={"black"}
                  bg={"white"}
                  value={tipoUsuario}
                  onChange={(e) => setTipoUsuario(e.target.value)}
                  style={{ padding: "0.3rem" }}
                  marginTop={2}
                >
                  <option value="">Selecionar</option>
                  <option value="Motorista">Motorista</option>
                  <option value="Passageiro">Passageiro</option>
                </Select>
                <Text
                  marginTop={5}
                  fontSize={"20"}
                  borderRadius={4}
                  height={10}
                  color={"white"}
                >
                  Selecione um tópico:
                </Text>
                <Select
                  color={"black"}
                  bg={"white"}
                  value={assunto}
                  onChange={(e) => setAssunto(e.target.value)}
                >
                  <option value="">Selecionar</option>
                  {topics.map((topic) => (
                    <option key={topic.value} value={topic.value}>
                      {topic.label}
                    </option>
                  ))}
                </Select>
                <Textarea
                  w={screen1000 ? 350 : 360}
                  h={screen1000 ? 40 : 150}
                  color={"black"}
                  marginTop={5}
                  padding={1}
                  background={"white"}
                  placeholder="Mensagem:"
                  required
                  onChange={(e) => setMensagem(e.target.value)}
                  value={mensagem}
                ></Textarea>
                <br />
                {/* <Box
                  paddingTop={screen1000 ? 2 : 2}
                  paddingLeft={screen1000 ? 6 : 8}
                // >
                //   <ReCAPTCHA
                //     sitekey="6Ld8WqwnAAAAAEZh-rjb7WUSVhaqlSB-utJAbdYU"
                //     onChange={onChange}
                //   />
                </Box> */}
                <Button
                  textAlign={"center"}
                  w={screen1000 ? 200 : 200}
                  marginLeft={screen1000 ? 20 : 20}
                  marginTop={"2"}
                  alignItems={"center"}
                  background={"#DCBC2E"}
                  type="submit"
                >
                  Enviar
                </Button>
              </form>
            </Box>
          </Box>
        </Box>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        scrollBehavior="inside"
        size="md"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Mensagem Enviada</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Mensagem enviada com sucesso!</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="yellow"
              onClick={handleCloseModal}
              backgroundColor="#DCBC2E"
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Grid>
  );
}
export default Support;
