import { theme } from "./theme";
import { ChakraProvider } from "@chakra-ui/react";
import { PagesRoutes } from "./Routes";
import "./global/styles.css";
import { AuthContextProvider } from "./context/auth";

export const App = () => (
  <ChakraProvider theme={theme}>
    <AuthContextProvider>
      <PagesRoutes />
    </AuthContextProvider>
  </ChakraProvider>
);

