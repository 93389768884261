import { useState, ChangeEvent } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Input,
  Text,
  VStack,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { AuthRepository } from "../../services/auth/index";
import { useForm } from "react-hook-form";

type FormData = {
  codigo: string;
};

export const VerifyEmail = () => {
  const toast = useToast();
  const {
    formState: { errors },
  } = useForm<FormData>();

  const location = useLocation();
  const navigate = useNavigate();
  const { token: initialToken } = location.state || {};
  const [token, setToken] = useState(initialToken);
  const { email } = location.state || {};
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [code, setCode] = useState(Array(6).fill(""));
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleCodeChange = (element: HTMLInputElement, index: number) => {
    const newCode = [...code];
    newCode[index] = element.value;
    setCode(newCode);

    if (element.value && index < 5) {
      const nextElement = document.getElementById(`code-input-${index + 1}`);
      if (nextElement) {
        (nextElement as HTMLInputElement).focus();
      }
    }
  };

  const handleBackspace = (element: HTMLInputElement, index: number) => {
    if (element.value === "" && index > 0) {
      const previousElement = document.getElementById(
        `code-input-${index - 1}`
      );
      if (previousElement) {
        (previousElement as HTMLInputElement).focus();
      }
    }
  };

  const onSubmit = async () => {
    const codigo = code.join("");
    try {
      setIsSubmitting(true);
      await AuthRepository.verifyEmail(token, codigo);
      setIsModalOpen(true);
    } catch (error) {
      setErrorMessage("Código inválido, tente novamente.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleResendCode = async () => {
    if (!email) {
      toast({
        title: "Email inválido.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const { tokenEmail: newTokenEmail } = await AuthRepository.resendEmail(
        email
      );
      setToken(newTokenEmail);
      toast({
        title: "Código reenviado com sucesso.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Erro ao reenviar o código.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    navigate("/");
  };

  return (
    <Box
      bg="#E5E7EB"
      borderRadius="md"
      boxShadow="md"
      maxW={{ base: "90%", md: "md" }}
      mx="auto"
      mt="10%"
      p={{ base: 3, md: 5 }}
      w="100%"
      marginBottom="6%"
    >
      <Text
        fontSize={{ base: "2xl", md: "3xl" }}
        fontWeight="bold"
        mb={4}
        textAlign="center"
      >
        Verificação de Email
      </Text>

      <Text fontSize="md" color="gray.600" textAlign="center" mb={6}>
        Informe o código que foi enviado para o seu e-mail para finalizar com o
        cadastro.
      </Text>
      <FormControl isInvalid={!!errors.codigo}>
        <HStack spacing={2} justify="center" mb={4}>
          {code.map((data, index) => (
            <Input
              key={index}
              id={`code-input-${index}`}
              type="text"
              maxLength={1}
              width="3.5rem"
              height="3.5rem"
              textAlign="center"
              fontSize="2xl"
              bg="white"
              color="black"
              borderRadius="md"
              boxShadow="md"
              _hover={{ borderColor: "blue.500" }}
              _focus={{ borderColor: "purple.500", outline: "none" }}
              value={data}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleCodeChange(e.target, index)
              }
              onKeyDown={(e) => {
                if (e.key === "Backspace") {
                  handleBackspace(e.target as HTMLInputElement, index);
                }
              }}
              onFocus={(e) => e.target.select()}
            />
          ))}
        </HStack>
        <FormErrorMessage color="red.300" fontSize="sm">
          {errors.codigo && errors.codigo.message}
        </FormErrorMessage>
      </FormControl>

      {errorMessage && (
        <Text color="red.300" mb={4}>
          {errorMessage}
        </Text>
      )}

      <VStack spacing={4} mt={4}>
        <Button
          width="100%"
          colorScheme="yellow"
          bg="yellow.400"
          _hover={{ bg: "yellow.300" }}
          isLoading={isSubmitting}
          onClick={onSubmit}
          boxShadow="md"
          fontSize="lg"
          py={6}
        >
          Verificar
        </Button>

        <Button
          variant="link"
          onClick={handleResendCode}
          mt={2}
          color="#CF0000"
        >
          Reenviar Código
        </Button>
      </VStack>

      <Modal isOpen={isModalOpen} onClose={handleModalClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Sucesso!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Seu cadastro foi realizado com sucesso.</Text>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="yellow" onClick={handleModalClose}>
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
