import {
  Button,
  Flex,
  FlexProps,
  HStack,
  IconButton,
  Image,
  TextProps,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import Logo from "../assets/logo.png";
import { RiMenuLine } from "react-icons/ri";
import { Sidebar } from "./sidebar";
import { Link, useLocation } from "react-router-dom";
import { useAuthContext } from "../context/auth";
import { useEffect } from "react";

const links = [
  { name: "Metas", link: "metas" },
  { name: "Objetivos", link: "objetivos" },
  { name: "Compromisso", link: "compromisso" },
  { name: "Assistência", link: "assistencia" },
  { name: "Segurança", link: "seguranca" },
  { name: "Contato", link: "contato" },
];

export const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [lessThan764] = useMediaQuery(["(max-width: 764px)"]);
  const location = useLocation();
  const { user } = useAuthContext();

  useEffect(() => {
    if (location.hash) {
      const section = document.getElementById(location.hash.slice(1));
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <Flex {...styles.container(lessThan764)}>
      <Link to={"/"}>
        <Flex h="4rem" alignItems="center">
          <Image src={Logo} h="3rem" />
        </Flex>
      </Link>

      <Flex alignItems="center" display={lessThan764 ? "flex" : "none"}>
        <Sidebar isOpen={isOpen} onClose={onClose} />
        <IconButton
          aria-label="menu-ib"
          onClick={onOpen}
          bg="#181818"
          colorScheme="blackAlpha"
        >
          <RiMenuLine color="white" />
        </IconButton>
      </Flex>

      <Flex display={lessThan764 ? "none" : "flex"} alignItems="center" gap={3}>
        {links.map((link, idx) => (
          <Link to={`/#${link.link}`} key={idx}>
            <Button bg="#181818" colorScheme="blackAlpha">
              {link.name}
            </Button>
          </Link>
        ))}

        <Link to="/termos">
          <Button bg="#181818" colorScheme="blackAlpha">
            Termos
          </Button>
        </Link>
        <Link to="/privacidade">
          <Button bg="#181818" colorScheme="blackAlpha">
            Privacidade
          </Button>
        </Link>
        <Link to="/suporte">
          <Button bg="#181818" colorScheme="blackAlpha">
            Suporte
          </Button>
        </Link>
      </Flex>

      {user ? (
        <Link to="/dashboard">
          <Button bgColor={"yellow.400"} fontSize={"14px"}>
            Minha Conta
          </Button>
        </Link>
      ) : (
        <HStack>
          <Link to="/sign-up">
            <Button bgColor={"yellow.400"} fontSize={"14px"}>
              CADASTRE-SE
            </Button>
          </Link>
        </HStack>
      )}
    </Flex>
  );
};

const styles = {
  container: (lessThan764: boolean): FlexProps => ({
    bgColor: "#181818",
    fontFamily: "Poppins",
    justifyContent: "space-evenly",
    alignItems: "center",
    paddingX: lessThan764 ? "1rem" : "2rem",
    position: "sticky" as const,
    top: 0,
    zIndex: 999999,
  }),

  letterAnimation: (): TextProps => ({
    letterSpacing: "6px",
    textShadow: "red 0 0, cyan 0 0",
    transition: "text-shadow 200ms",
  }),
};
