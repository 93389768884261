import { Box, Flex, useDisclosure } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { Header } from "./components/header";
import { Footer } from "./components/footer";

export function MainLayout() {
  const { onOpen } = useDisclosure();

  return (
    <Box
      bg="#222222"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      backgroundSize="contain"
      bgRepeat="repeat"
      backgroundPosition="center"
    >
      <Box flex="1">
        <Header onOpen={onOpen} />
        <Flex
          direction="column"
          minHeight="calc(100vh - 220px)"
          px={[4, 6]}
          pt={4}
        >
          <Outlet />
        </Flex>
      </Box>
      <Footer />
    </Box>
  );
}
